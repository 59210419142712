import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledUsp = styled.section``

const UspItem = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
`

const UspPosition = styled.div<{ view: string; hasDescription: boolean }>`
  ${(props) =>
    props.view === 'absolute' &&
    css`
      width: 100%;

      @media (min-width: 768px) {
        position: absolute;
        top: -${props.hasDescription ? 150 : 75}px;
      }
    `};
`

const UspItemIcon = styled(Image)`
  width: 60px;
  height: 60px;
`

const UspItemDescription = styled(ParseContent)`
  & a,
  & button {
    font-size: ${(props) => props.theme.font.size.m};
    margin-top: 0.5rem;
  }

  @media (min-width: 1200px) {
    font-size: ${(props) => props.theme.font.size.xm};
  }

  @media (min-width: 992px) {
    border-left: 1px solid ${(props) => props.theme.color.face.border};
  }
`

interface UspProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usp
}

const Usp: React.FC<UspProps> = ({ fields }) => (
  <StyledUsp
    className={
      fields.view === 'relative' ? 'my-1 my-lg-5 ' : 'pb-md-5 pt-md-0 pt-5'
    }
  >
    <div
      className={`container position-relative${
        fields.view === 'relative' ? ' py-5' : ' pb-md-5 mb-md-5 mb-lg-0'
      }`}
    >
      <UspPosition
        view={fields.view || 'relative'}
        hasDescription={!!fields.description}
      >
        {fields.description && (
          <div className="py-4">
            <ParseContent content={fields.description} />
          </div>
        )}
        <div className="row justify-content-center">
          {fields?.usp?.map((edge, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="col-12 col-md-4 mb-4">
              <UspItem className="d-flex d-md-block d-lg-flex align-items-center p-4">
                <UspItemIcon
                  className="mx-lg-0 mx-md-auto"
                  image={edge?.icon}
                  alt={edge?.alt || 'Kennisgebied'}
                />
                <UspItemDescription
                  className="pl-3 ml-3 ml-md-0 pl-md-0 ml-lg-4 pl-lg-4 mt-lg-0 mt-md-3"
                  content={edge?.description}
                />
              </UspItem>
            </div>
          ))}
        </div>
      </UspPosition>
    </div>
  </StyledUsp>
)

export default Usp
